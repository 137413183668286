<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  placeholder: {
    type: String,
    defualt: ''
  },
  height: {
    type: String,
    default: 'regular'
  },
  font: {
    type: String,
    default: 'sans'
  },
  fontSize: {
    type: String,
    default: 'base'
  },
  size: {
    type: String || null,
    default: 'large'
  },
  isValid: {
    type: Boolean,
    // eslint-disable-next-line vue/no-boolean-default
    default: true
  },
  typeInput: { type: String, default: 'text' },
  styleInput: { type: String, default: 'light' },
  outlined: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  filled: { type: Boolean, default: false },
  rounded: {
    type: String || null,
    default: 'sm'
  },
  modelValue: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:modelValue', 'focus', 'blur', 'input'])

const {
  placeholder,
  size,
  rounded,
  modelValue,
  outlined,
  disabled,
  filled,
  isValid,
  styleInput,
  font,
  fontSize,
  height
} = toRefs(props)

function handler(e) {
  emit('update:modelValue', e.target.value)
}

const _height = computed(() => {
  switch (height.value) {
    case 'small':
      return 'h-8'
    case 'regular':
      return 'h-9'
    case 'large':
      return 'h-11'
    case 'extra-large':
      return 'h-12'
  }
})

const _rounded = computed(() => {
  switch (rounded.value) {
    case 'sm':
      return 'rounded-sm'
    case 'md':
      return 'rounded-md'
    case 'lg':
      return 'rounded-lg'
    case 'xl':
      return 'rounded-xl'
    case '2xl':
      return 'rounded-2xl'
    case 'full':
      return 'rounded-full'
  }
})

const _font = computed(() => {
  switch (font.value) {
    case 'sans':
      return 'font-sans'
    case 'sf-pro':
      return 'font-sf-pro'
  }
})

const _fontSize = computed(() => {
  switch (fontSize.value) {
    case 'base':
      return 'text-base'
    case 'lg':
      return 'text-lg'
  }
})

const isActive = ref(false)
const windows = ref()
const isInFocus = ref(false)
const input = ref(null)

onClickOutside(windows, () => {
  isActive.value = false
})

function onFocus() {
  emit('focus')
  isInFocus.value = true
}

function onUnfocus() {
  emit('blur')
  isInFocus.value = false
}

function setFocusOnInput() {
  input.value.focus()
}

onUnmounted(() => {
  // input.value.removeEventListener('focus', onFocus)
  // input.value.removeEventListener('blur', onUnfocus)
})

const wrapperClasses = computed(() => {
  const classes = []

  if (styleInput.value === 'light') {
    classes.push('bg-white border border-transparent')
  }
  if (styleInput.value === 'gray') {
    classes.push('bg-gray-100 border border-transparent')
  }
  if (styleInput.value === 'dark-gray') {
    classes.push('bg-gray-800 border border-transparent')
  }
  if (styleInput.value === 'simple') {
    classes.push('bg-white')
  }

  if (outlined.value) {
    classes.push('!border')
  }

  if (isInFocus.value && styleInput.value !== 'simple') {
    classes.push('border !border-blue-400')
  }

  // if (filled.value) {
  //   classes.push('!bg-gray-50')
  // }
  if (!isValid.value) {
    classes.push('border !border-decorative-scarlet')
  }
  return classes
})

const innerClasses = computed(() => {
  const innerClasses = []
  if (styleInput.value === 'light') {
    innerClasses.push(
      'bg-white placeholder:text-gray-600 text-primary-100 focus:text-primary-500 focus:outline-none'
    )
  }
  if (styleInput.value === 'gray') {
    innerClasses.push(
      'bg-gray-100 placeholder:text-blue-300 text-primary-100 focus:text-primary-500 focus:outline-none'
    )
  }
  if (styleInput.value === 'dark-gray') {
    innerClasses.push('bg-gray-800 text-primary-100 focus:text-primary-500 focus:outline-none')
  }
  if (styleInput.value === 'simple') {
    innerClasses.push('bg-white placeholder:text-blue-200 !text-gray-300')
  }

  return innerClasses
})
</script>

<template>
  <div
    class="relative cursor-pointer font-normal w-full text-left rounded px-5 space-x-4 flex flex-row items-center"
    :class="[wrapperClasses, _rounded, _font, _fontSize, _height]"
    :disabled="disabled"
    @click="setFocusOnInput"
  >
    <div v-if="$slots.prepend" class="h-full flex items-center my-auto">
      <slot class="w-4 h-4" name="prepend" />
    </div>
    <div class="w-full">
      <input
        v-bind="attrs"
        ref="input"
        :type="typeInput"
        :placeholder="placeholder"
        class="font-normal border-none overflow-hidden text-left text-black rounded focus:outline-none w-full leading-none"
        :class="innerClasses"
        :disabled="disabled"
        :value="modelValue"
        @input="handler"
        @focus="onFocus"
        @blur="onUnfocus"
      />
    </div>
    <div v-if="$slots.suffix" class="h-full flex items-center">
      <slot name="suffix" />
    </div>

    <template v-if="$slots.popup">
      <slot name="popup" />
    </template>
  </div>
</template>

<style scoped>
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}
</style>
